import { Nav, Navbar, NavLink } from 'react-bootstrap';
import { useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { DataContext } from './../../context/DataContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';

import './theme.css';

const Navigation = ({ routes }) => {
    const { user, role, activeRoute, setActiveRoute, handleLogout, totalTokens, systemUsers, userAssets } = useContext(DataContext);
    const navigate = useNavigate();
    const AuthenticationRoutes = () => {
        return !user
            ? <Nav>
                <NavLink
                    as={Link}
                    eventKey={1}
                    href="#"
                    key='sign-in'
                    onClick={() => {
                        navigate("/", { state: {} });
                        setActiveRoute('/')
                    }}
                    className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                >
                    Sign in
                </NavLink>
            </Nav>
            : <div className="d-flex justify-content-center align-items-center">
                <Nav>
                    <NavLink
                        as={Link}
                        eventKey={2}
                        href="#"
                        key='sign-out'
                        onClick={async () => {
                            navigate("/login", { state: {} });
                            await handleLogout();
                            setActiveRoute('/login')
                        }}
                        role="button"
                        className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                    >

                        <span className="ml-2">Sign Out</span>
                    </NavLink>
                </Nav>
            </div>
    }

    const CustomRoutes = ({ navigation }) => {
        return user
            ? <Nav className="mr-auto">
                {
                    ['admin', 'user'].includes(role) &&
                    <>
                        <NavLink
                            as={Link}
                            eventKey={3}
                            to="/journeys-data"
                            key="journey-list"
                            className={`nav-item ${activeRoute === 'field' ? 'active' : ''}`}
                        >
                            Journeys
                        </NavLink>
                        <NavLink
                            as={Link}
                            eventKey={4}
                            to="/new-journey"
                            key="new-journey"
                            className={`nav-item ${activeRoute === 'journeyForm' ? 'active' : ''}`}
                        >
                            New Journey
                        </NavLink>
                    </>
                }
            </Nav>
            : <Nav className="mr-auto"></Nav>

    }

    return (
        <Navbar expand="sm">
            <Navbar.Brand
                onClick={() => {
                    navigate("/", {state: {}});
                    setActiveRoute('/')
                }} className='cursor-pointer'>
                <img alt="Logo" src="logo-sap.jpeg" height={30}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-main"/>
            <Navbar.Collapse id="navbar-main">
                <CustomRoutes />
                <AuthenticationRoutes />
            </Navbar.Collapse>
        </Navbar>
    )

}

export default Navigation;